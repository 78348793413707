import logo from './logo.svg';
import './App.css';

import LogoWhite from './assets/logo_white.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <form
          className='main_form'
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <img
            src={LogoWhite}
          />
          <input
            type={"text"}
            placeholder='Identifiant'
          />
          <input
            type={"password"}
            placeholder='Mot de passe'
          />
          <button
            type='submit'
          >
            Connexion
          </button>
        </form>
      </header>
    </div>
  );
}

export default App;
